a.navbar-brand {
	background-image: url(../../images/logos/dadw.png);
	background-size: contain;
	background-repeat: no-repeat;
	width: 90px;
	height: 60px;
	margin: 0;
}

.navbar-dark {
	.navbar-toggler {
		border-color: #FFF;
	}
	.nav-link { 
		text-transform: uppercase;
		font-weight: 600;
	}

	.navbar-collapse {
		align-items: start;
		.nav-item { 		
			padding-left: 10px;
			&:hover { 
				background-color: darken($primary,10%);
			}
		}
		.nav-link { 
			color: #FFF;
			text-align: left;
		}
	}
}

.navbar-subnav {
	background-color: darken($primary,10%); 
}

.btn {
	border-radius: 1.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	font-weight: 600;
}

.btn-primary {
    @include button-variant($primary, darken($primary, 7.5%), #FFF, lighten($primary,5%), lighten($primary, 10%), darken($primary,30%));
}

footer {
	h5 { font-weight: 900; text-transform: uppercase; }
	a, .nav-link { color: #FFF; }
}

.clickable {
	cursor: pointer;
}
#map-plug {display:none;}
 
#google-reviews {
display:flex;
flex-wrap:wrap;
/*display: grid;
grid-template-columns: repeat( auto-fit, minmax(320px, 1fr));*/
}
span.review-profile-image {
    float: left;
        padding: 0px 15px 0px 0px;
}
span.review-profile-image img {
    width: 55px;
}
.modal-backdrop.in{display:none;}
.review-item {
    border-bottom: solid 1px rgba(190,190,190,.35);
    margin: 5px auto;
    display: block;
    width: 100%;
    padding: 15px 0px;
}
 
@media ( max-width:1200px) {
  .review-item { flex: 1 1 40%; padding: 15px 10px; }          
}        
 
@media ( max-width:450px) {
    .review-item { flex: 1 1 90%; }           
}
 
.review-meta, .review-stars {text-align:left; font-size:115%;}
.review-author { text-transform: capitalize; font-weight:bold; }
.review-sep { display: none; }
.review-date {opacity:.6; display:block;}
.review-text {  line-height: 1.55;
    text-align: left;
    max-width: 72em;
    margin: auto;}
 
 
 
.review-stars ul {
display: inline-block;
list-style: none !important;
margin:0; padding:0;
margin-left: 65px;
}
 
.review-stars ul li {
float: left;
list-style: none !important;
margin-right: 1px;
line-height:1;
}
 
.review-stars ul li i {
  color: #E4B248;
  font-size: 1.4em;
  font-style:normal;
}
.review-stars ul li i.inactive { color: #c6c6c6;}
.star:after { content: "\2605"; } 
//
// Variables
// --------------------------------------------------


// Import Bootstrap functions to use inside variables values
@import '~bootstrap/scss/_functions';


//== Colors
//
// Gray and brand colors to use across the Theme.

// Grayscale
$white:                 #fff !default;
$gray-100:              #f7f7fc !default;
$gray-200:              #f3f3f9 !default;
$gray-300:              #e9e9f2 !default;
$gray-400:              #dfdfeb !default;
$gray-500:              #9e9fb4 !default;
$gray-600:              #737491 !default;
$gray-700:            	#5a5b75 !default;
$gray-800:              #4a4b65 !default;
$gray-900:              #37384e !default;
$black:                 #000 !default;

// Brand colors
$primary:               #529667 !default;
$secondary:             $gray-100 !default;
$info:		              #6a9bf4 !default;
$success:               #16c995 !default;
$warning:               #ffb15c !default;
$danger:                #b70300 !default;
$light:                 $white !default;
$dark:                  $gray-900 !default;

// Adding new color to default Bootstrap's $theme-colors map
$theme-colors: (
  'primary':    $primary,
  'secondary':  $secondary,
  'success':    $success,
  'info':       $info,
  'warning':    $warning,
  'danger':     $danger,
  'light':      $light,
  'dark':       $dark
) !default;

// Faded colors map
$theme-faded-colors: (
  'primary':    rgba($primary, .08),
  'success':    rgba($success, .08),
  'info':       rgba($info, .08),
  'warning':    rgba($warning, .08),
  'danger':     rgba($danger, .08),
  'light':      rgba($light, .08),
  'dark':       rgba($dark, .08)
) !default;

$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-size-base: 1.1rem;
.carousel-container {
	max-width: 2000px;
	margin-left: auto;
	margin-right: auto;		
}

.carousel {

	background-color: $primary;	

	.carousel-inner {
		margin-left: auto;
		margin-right: auto;
		max-width: 2000px;		
	}

	.carousel-caption-container {
		display: flex;

		&.right {
			justify-content: flex-end;
		}		
	}

	.carousel-caption {
		background: rgba(0, 0, 0, 0.5);
		padding: 25px;
        width: 40%; 
        position: static;

		h2 {
			font-size: 3rem;
			font-weight: 800;
		}
	}

	@include media-breakpoint-down(lg) {
		.carousel-caption {			
	        width: 100%;	        
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
		width: auto;		
	}			

    .carousel-item {
    	& > div {
    		min-height: 32rem;
    	}

        min-height: 32rem;
        background-position: center center;

		img {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            height: 32rem;
        }
    }
}

.block-card {
	border-radius: 1rem;

	.card-body { 
		min-height: 400px; 
		@include media-breakpoint-down(md) {
			min-height: 0px; 
		}
	}

    hr {
    	max-width: 50px;
    	color: $primary;
    	opacity: 1;
    	height: 3px;
    }

	.card-title {		
		font-weight: 900;
		text-transform: uppercase;		
	}

	img {
		object-fit: cover;
		min-height: 100%;
	}

	&.horizontal {

		img {
			border-top-left-radius: 1rem !important;
			border-bottom-left-radius: 1rem !important;		

			@include media-breakpoint-down(lg) {
				border-top-left-radius: 1rem !important;
				border-top-right-radius: 1rem !important;	
				border-bottom-left-radius: 0rem !important;	
			}				
		}

	}
}

.block-testimonial-feature {
	background-color: #000;
	background-position: right center;
	background-repeat: no-repeat;	
}

.block-footer-feature {
	background-color: $primary;
	font-size: 1.5rem;

	.btn-primary {
		border-color: #FFF;
	}

	a { 
		color: #FFF; 
		font-weight: bold;
	}
}

.block-team {
	.card {
		border: none;
	}

	h3 {
		font-weight: 600;
		color: $primary;
	}

	.rounded {
		border-radius: 25px !important;
	}
}

.booking-cal {
	button {
		border-width: 5px;
		border-color: $gray-400;
		border-radius: 15px;
		min-height: 120px;

		&:hover { 
			border-color: $primary;
			background-color: $primary;
			cursor: pointer;
			color: #FFF;
		}

		&.today { border-color: $primary; }

		&.unavailable { 
			color: $gray-400; 
			&:hover { 
				background-color: #FFF;
				cursor: inherit;
				color: $gray-400;
			}			
		}

		h2 {
			font-weight: bold;			
			small { 
				text-transform: lowercase; 
				vertical-align: super; 
				font-size: 0.5em;
			}
		}
	}
}

.tenant-search-container {
	.btn:focus,.btn:active {
		outline: none !important;
		box-shadow: none;
	}
}